export default class Logo {
    constructor(logo) {
        this.logo = logo;
        this.clone();
    }

    moveUp() {
        this.logo.style.top = `${this.logo.offsetTop}px`;
        setTimeout(() => this.logo.classList.add('header'));
    }

    moveDown(callback) {
        this.logo.style.top = `${this.initialLogo.offsetTop}px`;
        this.logo.classList.remove('header');
        this.logo.addEventListener('transitionend', () => {
            this.logo.style.top = 'auto';
            callback();
        }, { once: true });
    }

    clone() {
        this.initialLogo = this.logo.cloneNode(true);
        this.initialLogo.style.visibility = 'hidden';
        document.body.appendChild(this.initialLogo);
    }
}
